.spinnerContainer {
  margin-top: 10%;
}
.botonAdd {
  background-color: #21618c;
  border-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.botonDetalles {
  background-color: #5dade2;
  border-color: white;
}
