.sidebar {
  position: fixed;
  width: 20%;
  height: 100%;
  background: #04144c;
  font-size: 0.65em;
  margin-top: 10px;
  overflow-y: auto;
}

/* @media screen and (max-height: 450px) {
  .nav {
    font-size: 18px;
  }
} */

.nav {
  position: relative;
  margin: 0 15%;
  text-align: right;
  font-weight: bold;
}

.listado {
  list-style: none;
  margin-bottom: 90px;
}

.item {
  position: relative;
  margin: 3.2em 0;
}

.item.active a {
  color: white;
}

item:not(.active)::after {
  opacity: 0.2;
}

.item:not(.active):hover a {
  color: #2e86c1;
  cursor: pointer;
}

.item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.2em;
  background: black;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #04144c, #2e86c1);
}

.a {
  line-height: 5em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.4em;
  color: white;

  display: block;
  transition: all ease-out 300ms;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentRight {
  width: 80%;
  float: right;
  margin-top: 10px;
}

.contentRight a {
  position: relative;
}

.contentRight p {
  font-size: 1em;
  letter-spacing: 0.1em;
  color: black;
  font-weight: bold;
  margin-top: 20px;
}
