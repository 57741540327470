.cogs {
  color: #e0dede;
  font-size: 30px;
  color: #21618c;
}
.navlink {
  display: block;
  margin-right: 20px;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: "white";
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.dropdownItem {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.linkInButton {
  text-decoration: none;
}

.spinnerContainer {
  margin-top: 10%;
}

.fadeCard {
  cursor: pointer;
  animation: fadeIn 1.5s linear;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0px, 20px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
