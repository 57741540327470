.card:hover {
  transform: scale(1.1);
}

.card {
  width: 18rem;
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
