.dropContainer {
  border: 2px dashed #1b4f72;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 70px;
  text-align: center;
  font: 21pt bold arial;
  color: #1b4f72;
}
.area {
  width: 100%;
  padding: 15px;
  background: white;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.error {
  color: red;
}
