.subtitulo {
  font: bold 100% sans-serif;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  background: #eccc83;
  border-radius: 0.25em;
  color: #000;
  margin: 0 0 1em;
  padding: 0.5em 0;
  padding-left: 20px;
  text-align: left;
}
.spinnerContainer {
  margin-top: 10%;
}
