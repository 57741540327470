.spinnerContainer {
  margin-top: 10%;
}

.titulo {
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;
  background: #04144c;
  border-radius: 0.25em;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.5em 0;
}

.subtitulo {
  font: bold 100% sans-serif;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  background: #eccc83;
  border-radius: 0.25em;
  color: #000;
  margin: 0 0 1em;
  padding: 0.5em 0;
  padding-left: 20px;
  text-align: left;
}
/* table */
.table {
  font-size: 75%;
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 2px;
  margin-bottom: 10px;
}

.cell {
  border-width: 1px;
  padding: 0.5em;
  position: relative;
  text-align: left;
  border-radius: 0.25em;
  border-style: solid;
}

.td {
  border-width: 1px;
  padding: 0.5em;
  position: relative;
  text-align: left;
  border-radius: 0.25em;
  border-style: solid;
  border-color: #ddd;
}

.th {
  background: #eee;
  border-color: #bbb;
}

.meta {
  margin: 0 0 3em;
  width: 36%;
}

/* table meta */
table.meta th {
  width: 30%;
}
table.meta td {
  width: 60%;
}

.inactive {
  color: #fff !important;
  background-color: #c9cdca !important;
}

.active {
  color: #fff !important;
  background-color: #492c7c !important;
}

.tag {
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 7px;
  margin-right: 5px;
  padding: 8px 16px !important;
  border-radius: 8px;
  display: inline-block;
  width: auto;
  text-align: center;
}
.firmaText {
  text-align: center;
  font-weight: bold;
}
